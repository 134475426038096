export let environment = {
  production: false,
  FLAGS: {
    DISPLAY_DASHBOARD: "#@#FLAGS_DISPLAY_DASHBOARD#@#",
    DISPLAY_USERS: "#@#FLAGS_DISPLAY_USERS#@#",
    DISPLAY_ADVISERS: "#@#FLAGS_DISPLAY_ADVISERS#@#",
    DISPLAY_FARMS: "#@#FLAGS_DISPLAY_FARMS#@#",
    DISPLAY_MAPS: "#@#FLAGS_DISPLAY_MAPS#@#",
    DISPLAY_CROPS: "#@#FLAGS_DISPLAY_CROPS#@#",
    DISPLAY_MARKET: "#@#FLAGS_DISPLAY_MARKET#@#",
    DISPLAY_IRRIGATION_CONTROL: "#@#FLAGS_DISPLAY_IRRIGATION_CONTROL#@#",
    DISPLAY_IRRIGATION_MANAGER: "#@#FLAGS_DISPLAY_IRRIGATION_MANAGER#@#",
    DISPLAY_FARM_MANAGEMENT: "#@#FLAGS_DISPLAY_FARM_MANAGEMENT#@#",
    DISPLAY_PEST_CONTROL: "#@#FLAGS_DISPLAY_PEST_CONTROL#@#",
    DISPLAY_NUTRITION: "#@#FLAGS_DISPLAY_NUTRITION#@#",
    DISPLAY_IRRIDESK: "#@#FLAGS_DISPLAY_IRRIDESK#@#",
    DISPLAY_CLIMA_PLANTA_SOL: "#@#FLAGS_DISPLAY_CLIMA_PLANTA_SOL#@#",
    DISPLAY_AGRIGENIUS_VITE: "#@#FLAGS_DISPLAY_AGRIGENIUS_VITE#@#",
    DISPLAY_AGRIGENIUS_OLIVO: "#@#FLAGS_DISPLAY_AGRIGENIUS_OLIVO#@#",
    DISPLAY_IRRIGATION_MANAGER_EARLY_ACCESS: "#@#FLAGS_DISPLAY_IRRIGATION_MANAGER_EARLY_ACCESS#@#",

    //Submodules flags

    DISPLAY_IRRIGATION_CONTROL_SUBMODULE_UNITS: "#@#FLAGS_DISPLAY_IRRIGATION_CONTROL_SUBMODULE_UNITS#@#",
    DISPLAY_IRRIGATION_CONTROL_SUBMODULE_MALFUNCTIONS: "#@#FLAGS_DISPLAY_IRRIGATION_CONTROL_SUBMODULE_MALFUNCTIONS#@#",
    DISPLAY_IRRIGATION_CONTROL_SUBMODULE_GRAPHICS: "#@#FLAGS_DISPLAY_IRRIGATION_CONTROL_SUBMODULE_GRAPHICS#@#",
    DISPLAY_IRRIGATION_CONTROL_SUBMODULE_AUDITS: "#@#FLAGS_DISPLAY_IRRIGATION_CONTROL_SUBMODULE_AUDITS#@#",
    DISPLAY_IRRIGATION_CONTROL_SUBMODULE_EXPORT: "#@#DISPLAY_IRRIGATION_CONTROL_SUBMODULE_AUDITS#@#",

    DISPLAY_IRRIGATION_MANAGER_SUBMODULE_ADMIN: "#@#FLAGS_DISPLAY_IRRIGATION_MANAGER_SUBMODULE_ADMIN#@#",
    DISPLAY_IRRIGATION_MANAGER_SUBMODULE_DASHBOARD: "#@#FLAGS_DISPLAY_IRRIGATION_MANAGER_SUBMODULE_DASHBOARD#@#",
    DISPLAY_IRRIGATION_MANAGER_SUBMODULE_FARMS: "#@#FLAGS_DISPLAY_IRRIGATION_MANAGER_SUBMODULE_FARMS#@#",
    DISPLAY_IRRIGATION_MANAGER_SUBMODULE_SOLIDS: "#@#FLAGS_DISPLAY_IRRIGATION_MANAGER_SUBMODULE_SOLIDS#@#",
    DISPLAY_IRRIGATION_MANAGER_SUBMODULE_FERTILIZERS: "#@#FLAGS_DISPLAY_IRRIGATION_MANAGER_SUBMODULE_FERTILIZERS#@#",
    DISPLAY_IRRIGATION_MANAGER_SUBMODULE_SEND_PROGRAM: "#@#FLAGS_DISPLAY_IRRIGATION_MANAGER_SUBMODULE_SEND_PROGRAM#@#",
    DISPLAY_IRRIGATION_MANAGER_SUBMODULE_RESULTS: "#@#FLAGS_DISPLAY_IRRIGATION_MANAGER_SUBMODULE_RESULTS#@#",
    DISPLAY_IRRIGATION_MANAGER_SUBMODULE_CONFIG: "#@#FLAGS_DISPLAY_IRRIGATION_MANAGER_SUBMODULE_CONFIG#@#",
    DISPLAY_IRRIGATION_MANAGER_SUBMODULE_ADMIN_CLIENT: "#@#FLAGS_DISPLAY_IRRIGATION_MANAGER_SUBMODULE_ADMIN_CLIENT#@#",
   
    DISPLAY_WEATHER_PLANT_SOIL_SUBMODULE_AGROCLIMATIC_INDEX: "#@#FLAGS_DISPLAY_WEATHER_PLANT_SOIL_SUBMODULE_AGROCLIMATIC_INDEX#@#",
    DISPLAY_WEATHER_PLANT_SOIL_SUBMODULE_GRAPHICS: "#@#FLAGS_DISPLAY_WEATHER_PLANT_SOIL_SUBMODULE_GRAPHICS#@#",
    
    DISPLAY_FARM_MANAGEMENT_SUBMODULE_FARMS: "#@#FLAGS_DISPLAY_FARM_MANAGEMENT_SUBMODULE_FARMS#@#",
    DISPLAY_FARM_MANAGEMENT_SUBMODULE_CONFIG: "#@#FLAGS_DISPLAY_FARM_MANAGEMENT_SUBMODULE_CONFIG#@#",
    
    DISPLAY_IRRIDESK_SUBMODULE_CHANNELS: "#@#FLAGS_DISPLAY_IRRIDESK_SUBMODULE_CHANNELS#@#",
    DISPLAY_IRRIDESK_SUBMODULE_SENSORS: "#@#FLAGS_DISPLAY_IRRIDESK_SUBMODULE_SENSORS#@#",
    
    DISPLAY_ADVISERS_SUBMODULE_MY_ADVISERS: "#@#FLAGS_DISPLAY_ADVISERS_SUBMODULE_MY_ADVISERS#@#",
  },
  URL: {
    API_IRRIGATION_MANAGER: "https://backend.irrigation-manager.com/graphql/",
    API_IRRIGATION_MANAGER_EA: "https://vegga-pre.azure-api.net/irrigation-manager-service/graphql/",
    ECOMMERCE: "https://store.vegga.tech",
    BASF: "https://cloudtest.horta-srl.it/",
    AGRONIC_WEB: "https://www.agronicweb.com",
  },
  BASE_URL_IRRIGATION_MANAGER: "irrigation-manager",
  BASE_URL_IRRIGATION_MANAGER_EA: "ea-irrigation-manager",
};
